import { readState } from "@/__main__/app-state.mjs";
import { writeSettings } from "@/app/actions.mjs";
import clone from "@/util/clone.mjs";

export function onMapMarkerChange(key, value) {
  // @ts-ignore
  const oldSettings = readState.settings.palworld.overlays?.mapMarkers || {};
  const newSettings = clone(oldSettings);
  newSettings[key] = value;

  writeSettings(["palworld", "overlays", "mapMarkers"], newSettings);
}
