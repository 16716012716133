import React from "react";
import { css, styled } from "goober";

import { GAME_COLORS } from "@/app/constants.mjs";
import { GAME_SYMBOL_PALWORLD } from "@/game-palworld/definition.mjs";

export const overlayContainer = () => css`
  --overlay-content-width: calc(var(--sp-1) * 91.25);
  --overlay-width: calc(
    var(--overlay-content-width) + var(--scrollbar-width) + var(--sp-2)
  );
  --overlay-background-color: hsla(228, 24%, 4%, 0.9);
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

export const Container = styled("div", React.forwardRef)`
  display: flex;
  flex-direction: column;
  width: var(--overlay-content-width);
  position: absolute;
  top: var(--sp-1);
  left: var(--sp-1);
`;

export const Header = styled("div", React.forwardRef)`
  background: var(--overlay-background-color);
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: var(--br);
  padding: var(--sp-1);
  width: var(--overlay-content-width);
  margin: var(--sp-1);

  label {
    background: none;
    border: unset !important;
    width: 100%;
    outline: none;
    height: var(--sp-10);
  }

  svg {
    fill: var(--shade0);
  }
  input {
    color: var(--shade0);
    &::placeholder {
      color: var(--shade0) !important;
    }
  }

  .header-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .settings {
      height: var(--sp-4);
      width: var(--sp-4);
      cursor: pointer;
      color: var(--shade0);
      margin-right: var(--sp-2);

      &:hover {
        color: var(--shade0-75);
      }
    }
  }
`;

export const LogoContainer = styled("div", React.forwardRef)`
  z-index: 10;
  display: flex;
  align-items: center;
  margin: var(--sp-3_5) 0 var(--sp-3_5) var(--sp-3);

  svg.logo {
    display: block;
    height: var(--sp-2);
    width: var(--sp-17);
  }
`;

export const Content = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: var(--sp-3);
  max-height: calc(10 * var(--sp-10));
  overflow-y: auto;
  width: var(--overlay-width);
  &::-webkit-scrollbar-thumb {
    background: var(--overlay-background-color);
  }
`;

export const SettingsContainer = styled("div")`
  position: absolute;
  bottom: var(--sp-2);
  left: var(--sp-2);
  background: var(--overlay-background-color);
  border-radius: var(--br);
  padding: var(--sp-4);

  .title {
    margin-bottom: var(--sp-2);
  }

  .toggles {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
    > div {
      flex-direction: row-reverse;
    }
  }

  .unmark-all {
    margin-top: var(--sp-4);
  }
`;

export const PalCardCSS = () => css`
  --game-color: ${GAME_COLORS[GAME_SYMBOL_PALWORLD]};

  border-radius: var(--br);
  background: var(--overlay-background-color);

  display: flex;
  flex-direction: row;
  gap: var(--sp-3);
  .pal-image {
    width: var(--sp-24);
    height: var(--sp-24);
    border: 1px solid hsla(222, 13%, 40%, 0.15);
    border-radius: var(--br);
  }
  .meta {
    display: flex;
    flex-direction: column;
    gap: var(--sp-1);
    width: 100%;
    .pal-name {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      gap: var(--sp-2);
      .elements {
        display: inline-flex;
        gap: var(--sp-1);
      }
    }
    .meta-line {
      display: flex;
      flex-direction: row;
      gap: var(--sp-1);
      align-items: center;

      &.space-between {
        justify-content: space-between;
      }
      &.start {
        justify-content: start;
      }

      p.label {
        align-self: start;
      }

      .work-skills {
        height: var(--sp-5);
        p {
          display: inline-flex;
        }
      }

      .drops {
        display: flex;
        flex-direction: column;
        align-items: end;
        gap: var(--sp-1);
        .drop {
          display: flex;
          flex-direction: row;
          gap: var(--sp-1);
          p.label {
            color: var(--game-color);
          }
          .drop-image {
            width: var(--sp-5);
            height: var(--sp-5);
          }
        }
      }
    }
  }
`;

export const NoResultsFoundCSS = () => css`
  background: var(--overlay-background-color);
  width: var(--overlay-width);
  margin-left: var(--sp-1);
  height: var(--sp-20);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PalCardOuter = () => css`
  transform-origin: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: var(--overlay-content-width);
  margin: var(--sp-1);

  &:hover::after {
    opacity: 1;
  }

  --x: 50%;
  --y: 50%;

  &::after {
    position: absolute;
    pointer-events: none;
    content: " ";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: radial-gradient(
      ellipse 100% 100% at var(--x) var(--y),
      var(--shade0-15),
      hsla(var(--shade0-hsl) / 0)
    );
    opacity: 0;
    transition: 0.2s opacity ease;
    z-index: 10;

    outline: 2px solid var(--shade0-50);
    outline-offset: 2px;
    border-radius: var(--br);
  }
`;

export const SelectedPalCSS = () => css`
  width: 100%;
  border-radius: var(--br);
  outline: var(--sp-px) solid var(--shade0-50);
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--sp-2);
    padding: var(--sp-2);

    p {
      flex: 1;
      line-height: var(--sp-6);
    }

    img {
      border-radius: var(--br);
    }

    svg {
      width: var(--sp-5);
      height: var(--sp-5);
      cursor: pointer;
    }
  }
`;
