import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button";
import { Card } from "clutch/src/Card/Card.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { onMapMarkerChange } from "@/game-palworld/actions.mjs";
import { ELEMENTS } from "@/game-palworld/constants/elements.mjs";
import { WORK_SUITABILITIES } from "@/game-palworld/constants/work-suitabilities.mjs";
import {
  Container,
  Content,
  Header,
  LogoContainer,
  NoResultsFoundCSS,
  overlayContainer,
  PalCardCSS,
  PalCardOuter,
  SelectedPalCSS,
  SettingsContainer,
} from "@/game-palworld/MapMarkerOverlay.style.jsx";
import type { Pal } from "@/game-palworld/models/model-wiki.mjs";
import formatDropChance from "@/game-palworld/utils/format-drop-chance.mjs";
import { formatPaldeckId } from "@/game-palworld/utils/format-paldeck-id.mjs";
import { getByInternalId } from "@/game-palworld/utils/get-by-internal-id.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import Close from "@/inline-assets/circle-close.svg";
import SearchIcon from "@/inline-assets/search-icon.svg";
import BlitzWordmarkWithGG from "@/inline-assets/wordmark-with-gg.svg";
import { hiddenElement } from "@/shared/OverlayContainerWithAd.style.jsx";
import { classNames } from "@/util/class-names.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TOGGLE_SETTINGS = [
  {
    label: ["palworld:overlay.players", "Players"] as Translation,
    key: "show_players",
    value: false,
  },
  {
    label: ["palworld:overlay.fastTravels", "Fast Travels"] as Translation,
    key: "show_travels",
    value: false,
  },
  {
    label: ["palworld:overlay.effigies", "Effigies"] as Translation,
    key: "show_relics",
    value: false,
  },
  {
    label: ["palworld:overlay.treasures", "Treasures"] as Translation,
    key: "show_treasures",
    value: false,
  },
  {
    label: ["palworld:overlay.eggs", "Eggs"] as Translation,
    key: "show_eggs",
    value: false,
  },
  {
    label: ["palworld:overlay.stone", "Stone"] as Translation,
    key: "show_stones",
    value: false,
  },
  {
    label: ["palworld:overlay.ore", "Ore"] as Translation,
    key: "show_metals",
    value: false,
  },
  {
    label: ["palworld:overlay.paldium", "Paldium"] as Translation,
    key: "show_paladiums",
    value: false,
  },
];

function Settings({ settings }) {
  const { t } = useTranslation();

  const toggles = useMemo(() => {
    return TOGGLE_SETTINGS.filter(
      (s) => typeof settings[s.key] === "boolean",
    ).map((s) => {
      s.value = settings[s.key];
      return s;
    });
  }, [settings]);

  const unmarkAll = useCallback(() => {
    blitzMessage(EVENTS.PALWORLD_REQUEST_DATA, { name: "unmark_all" });
  }, []);

  return (
    <SettingsContainer>
      <p className="type-title--bold title">
        {t("palworld:overlay.toggleMarkers", "Toggle locations")}
      </p>
      <div className="toggles">
        {toggles.map((toggle) => {
          return (
            <ToggleSwitch
              key={toggle.key}
              value={toggle.value}
              onChange={(value) => onMapMarkerChange(toggle.key, value)}
              className="toggle"
              labelText={t(...toggle.label)}
            />
          );
        })}
      </div>
      <Button className="unmark-all" onClick={unmarkAll} emphasis="high">
        {t("palworld:overlay.clearSelectedMarkers", "Clear compass markers")}
      </Button>
    </SettingsContainer>
  );
}

function PalCard({ pal, onClick }: { pal: Pal; onClick: () => void }) {
  const { t } = useTranslation();
  const { items } = useWikiData();

  return (
    <Card
      padding={8}
      className={PalCardCSS()}
      classNameOuter={PalCardOuter()}
      onClick={onClick}
    >
      <img
        src={`${appURLs.CDN_PLAIN}/${pal.imageUri}`}
        className="pal-image"
        width={96}
        height={96}
        alt={pal.id}
      />
      <div className="meta">
        <div className="meta-line space-between">
          <p className="type-title--bold shade0 pal-name">
            {t(...pal.label)}
            <span className="elements">
              {pal.elements.map((key) => {
                const element = ELEMENTS[key];

                return (
                  <img
                    key={key}
                    src={element.iconSrc}
                    width={18}
                    height={18}
                    alt={t(...element.label)}
                    className="pal-element"
                    title={t(...element.label)}
                  />
                );
              })}
            </span>
          </p>
          <p className="type-caption shade3">
            {formatPaldeckId(pal.paldeckId, pal.paldeckIdSuffix)}
          </p>
        </div>
        <div className="meta-line space-between">
          <p className="type-callout shade2 label">
            {t("palworld:workSkills", "Work Skills")}
          </p>
          <div className="work-skills">
            {Object.entries(WORK_SUITABILITIES).map(([key, { src }]) => {
              if (!pal.workSutiability[key] || pal.workSutiability[key] === 0)
                return null;
              return (
                <p className="type-callout--semi shade0" key={key}>
                  <span>
                    <img src={src} width={20} height={20} alt={key} />
                  </span>{" "}
                  {pal.workSutiability[key]}
                </p>
              );
            })}
          </div>
        </div>
        <div className="meta-line space-between">
          <p className="type-callout shade2 label">
            {t("palworld:info.drops", "Drop(s)")}
          </p>
          <div className="drops">
            {pal.drops.map(({ internalId, max, min, rate }) => {
              const item =
                items[internalId] ?? getByInternalId(internalId, items);
              const dropChance = formatDropChance(t, max, min, rate);
              if (!item) return null;
              return (
                <div className="drop" key={item.id}>
                  <img
                    src={`${appURLs.CDN_PLAIN}/${item.imageUri}`}
                    width={20}
                    height={20}
                    alt={item.id}
                    className="drop-image"
                    title={dropChance}
                  />
                  <p className="type-callout--semi label">{t(...item.label)}</p>
                  <p className="type-caption--bold shade1 chance">
                    {dropChance}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}

function SelectedPal({ pal, onClick }: { pal: Pal; onClick: () => void }) {
  const { t } = useTranslation();
  return (
    <div className={SelectedPalCSS()}>
      <div>
        <img
          src={`${appURLs.CDN_PLAIN}/${pal.imageUri}`}
          alt={pal.id}
          width={24}
          height={24}
        />
        <p className="type-title--semi shade0">{t(...pal.label)}</p>
        <Close onClick={onClick} />
      </div>
    </div>
  );
}

function MapMarkerOverlay() {
  const state = useSnapshot(readState);
  const { pals, items, spawnLocations } = useWikiData();

  // @ts-ignore
  const overlays = state.settings.palworld?.overlays;
  const isMapMarkerEnabled = overlays?.isMapMarkerEnabled || true;
  const settings = useMemo(
    () => overlays?.mapMarkers || {},
    [overlays?.mapMarkers],
  );
  const [searchText, setSearch] = useState("");
  const [selectedPalId, setSelectedPalId] = useState<string | null>(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const draggableAreaRef = useRef();
  const { t } = useTranslation();

  const selectPal = useCallback(
    async (palId) => {
      const pal = pals[palId];
      setSearch("");
      setSelectedPalId(palId);
      if (!spawnLocations[pal.internalId]) return;
      const spawnLocation = spawnLocations[pal.internalId];
      const worldState = await blitzMessage(EVENTS.PALWORLD_REQUEST_DATA, {
        name: "update_world",
      });

      // @ts-ignore
      const isDayTime = worldState?.data?.world?.time === 1;
      const positions = [
        ...spawnLocation[0],
        ...(isDayTime ? spawnLocation[1] : spawnLocation[2]),
      ].map(([x, y, z]) => ({ x, y, z }));
      const setMarkerData = {
        name: "set_map_mark",
        data: {
          name: pal.label[1],
          texture: pal.internalId,
          positions,
        },
      };
      blitzMessage(EVENTS.PALWORLD_REQUEST_DATA, setMarkerData);
    },
    [pals, spawnLocations],
  );

  const deselectPal = useCallback(() => {
    const pal = pals[selectedPalId];
    setSelectedPalId(null);
    if (!pal) return;
    blitzMessage(EVENTS.PALWORLD_REQUEST_DATA, {
      name: "del_map_mark",
      data: {
        name: pal.label[1],
      },
    });
  }, [selectedPalId, pals]);

  const filteredPals = useMemo(() => {
    if (!pals || selectedPalId || !searchText.length) return [];
    const allPals = Object.values(pals);
    const named = allPals.filter((pal) =>
      pal.label[1].toLowerCase().includes(searchText.toLowerCase()),
    );
    const other = allPals.filter(
      (pal) =>
        pal.elements.findIndex((e) =>
          e.toLowerCase().includes(searchText.toLowerCase()),
        ) > -1 ||
        Object.keys(pal.workSutiability).findIndex((w) => {
          const work = WORK_SUITABILITIES[w];
          return work?.label?.[1]
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase());
        }) > -1 ||
        pal.drops.findIndex((d) => {
          const drop =
            items[d.internalId] ?? getByInternalId(d.internalId, items);
          return drop?.label?.[1]
            ?.toLowerCase()
            ?.includes(searchText.toLowerCase());
        }) > -1,
    );
    return Array.from(new Set([...named, ...other]));
  }, [items, pals, searchText, selectedPalId]);

  useEffect(() => {
    if (!IS_APP && !IS_NODE) {
      setIsMapOpen(true);
    }
    handleMessage(EVENTS.PALWORLD_EVENT_MAP, (isMapOpen) => {
      setIsMapOpen(isMapOpen as boolean);
    });

    return () => {
      handleMessage(EVENTS.PALWORLD_EVENT_MAP, () => {});
    };
  }, []);

  useEffect(() => {
    if (Object.keys(settings).length === 0) return;
    blitzMessage(EVENTS.PALWORLD_REQUEST_DATA, {
      name: "set_settings",
      data: settings,
    });
  }, [settings]);

  if (!pals || !items || !spawnLocations || !isMapMarkerEnabled) return null;

  return (
    <div
      className={
        classNames(!isMapOpen ? hiddenElement : "", overlayContainer())
          .className
      }
    >
      <Container>
        <Header>
          <div className="header-title">
            <LogoContainer ref={draggableAreaRef}>
              <BlitzWordmarkWithGG className="logo" />
            </LogoContainer>
          </div>
          {!selectedPalId ? (
            <TextInput
              defaultValue={searchText}
              placeholder={t(
                "palworld:search.palsOrItems",
                "Search Pals Or Items",
              )}
              onValueChange={setSearch}
              Icon={SearchIcon}
            />
          ) : (
            <SelectedPal
              pal={pals[selectedPalId]}
              onClick={() => deselectPal()}
            />
          )}
        </Header>
        <Content>
          {filteredPals.map((pal) => {
            return (
              <PalCard
                key={pal.internalId}
                pal={pal}
                onClick={() => selectPal(pal.id)}
              />
            );
          })}
          {filteredPals.length === 0 && searchText.length > 0 ? (
            <Card padding={8} className={NoResultsFoundCSS()}>
              <p className="type-body2">
                {t("palworld:overlay.noResultsFound", "No results found")}
              </p>
            </Card>
          ) : null}
        </Content>
      </Container>
      <Settings settings={settings} />
    </div>
  );
}

export default MapMarkerOverlay;

export function meta() {
  return {
    title: [null, "Map marker overlay"],
    description: [null, "Map marker overlay"],
  };
}
